:root {
  --yellow-festgourmet: #F49F0A;
}

.block-margin {
  display: 'flex';
  width: '100%';
  margin-bottom: 10px;
}

.row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  /* 2/3 and 1/3 */
  gap: 10px;
}

.row-email {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* 2/3 and 1/3 */
  gap: 10px;
}

.row-address {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  /* 2/3 and 1/3 */
  gap: 10px;
}

.custom-input {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
  display: block;
  width: 100%;
  height: 40px;
  font-size: 18px;
}

.row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.control-image {
  margin-right: 50px;
}

.thumbnail-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
  height: 100%;
}

.section-title {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
  color: gray;
}

.contacts {
  margin-right: 10px;
}

.section-subtitle {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 5px;
  color: black
}

.row-profile {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* 2/3 and 1/3 */
  gap: 10px;
  margin-bottom: 50px;
}

.row-50 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* 2/3 and 1/3 */
  gap: 20px;
  margin-top: 40px;
}

.checkbox-label {
  font-size: 22px;
}

.radio-group {
  display: flex;
  flex-direction: column;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  /* Adjust opacity here */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure it's on top of everything */
}

.spinner {
  border: 4px solid rgba(230, 162, 35, 0.3);
  /* Spinner color and opacity */
  border-top: 4px solid #F49F0A;
  /* Spinner color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}